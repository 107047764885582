.Portafolio__Contact{
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 3rem auto;
    display: flex;
    width: 900px;
}

.Portafolio__Contact-social__warp{
    justify-content: center;
    flex-direction: row;
    align-items: center;
    display: flex;
    margin: 2rem;
}

@media only screen and (max-width : 821px){
    .Portafolio__Contact{
        width: 90%;
    }
}