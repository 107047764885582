.Portafolio__scard{
    background-color: rgba(255,255,255,0.15);
    border: 1px solid #ffffff;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    width: 350px;
}

.Portafolio__scard-title{
    margin-bottom: 2rem;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
}

.Portafolio__scard-grid{
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 10px;
    display: grid;
}

@media (max-width: 821px) {
    .Portafolio__scard{
        width: 90%;
    }
}