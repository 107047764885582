.Portafolio__acard{
    background-color: rgba(255,255,255,0.15);
    border: 1px solid #ffffff;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 2rem;
    padding: 1rem 3rem;
    display: flex;
    width: 100%;
}

@media only screen and (max-width : 821px){
    .Portafolio__acard{
        flex-direction: column;
        width: 100%;
    }
}

.Portafolio__acard-title{
    text-align: center;
    margin: 1rem 0 0 0;
    color: #ffffff;
    font-size: 30px;
}

.Portafolio__acard-conference{
    margin: 1rem 0 1rem 0;
    color: #ffffff;
    font-size: 22px;
}

@media only screen and (max-width : 821px){
    .Portafolio__acard-conference{
        margin: 2rem 0;
        text-align: center;
        font-size: 18px;
    }
}

.Portafolio__acard-location, .Portafolio__acard-year{
    color: #ffffff;
    font-size: 16px;
}