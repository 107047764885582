*{
  /* outline: red dashed 1px; */
  scroll-behavior: smooth;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body{
  background-color: var(--color-bg);
  font-family: var(--font-family);
  color: var(--color-text);
}

a{
  text-decoration: none;
  color: unset;
}

.landing{
  background: #3f3f3f;
}

.section{
  padding: 4rem 6rem;
  margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
  .section{
    padding: 4rem;
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section{
    padding: 4rem 2rem;
    margin: 4rem 2rem;
  }
}