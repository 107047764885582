.Portafolio__Experience{
    margin: 1rem auto;
	padding-top: 8rem;
	width: 800px;
}

@media (max-width: 821px) {
    .Portafolio__Experience {
        margin: 4rem 0 0 0;
        width: 100%;
    }
}

.Portafolio__Experience-container{
	border-left: 2px solid #ccc;
    margin:2rem 0;
}

@media (max-width: 821px) {
    .Portafolio__Experience-container {
        margin: 4rem 2.5rem;
    }
}

.Portafolio__Experience-container__box{
	background-color: rgba(255,255,255,0.15);
	transition: all 0.4s;
    border-radius: 0 10px 10px 0;
	position: relative;
    color: #ffffff;
	padding: 20px;
    width: 100%;
}

/* .Portafolio__Experience-container__box:hover{
	box-shadow: 0px 3px 12px 0px #ccc;
	border: 1px solid transparent;
} */

.Portafolio__Experience-container__box::before{
	background-color: #11a372;
	border: 2px solid white;
	right: calc(100% - 8px);
	position: absolute;
	border-radius: 50%;
	height: 16px;
	content: '';
	width: 16px;
	top: 4;
}

.Portafolio__Experience-container__box h4{
    text-align: justify;
	position: relative;
	color: #ffffff;
}

.Portafolio__Experience-container__box h3{
    padding: 10px 0px 6px;
    text-align: justify;
	color: #ffffff;
	font-size: 20px;
}

.Portafolio__Experience-container__box p{
    text-align: justify;
	line-height: 1.2;
	font-size: 17px;
	color: #ffffff;
    margin: 1rem;
}