.Portafolio__ppcard{
    background-color: rgba(255,255,255,0.15);
    justify-content: flex-start;
    border: 1px solid #ffffff;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 2.5rem;
    display: flex;
    width: 450px;
}

.Portafolio__ppcard img{
    width: 100%;
}

@media only screen and (max-width : 821px){
    .Portafolio__ppcard{
        width: 100%;
    }
}

.Portafolio__ppcard h3{
    text-align: justify;
    color: #ffffff;
    margin-top: 1rem;
    font-size: 30px;
}

@media only screen and (max-width : 821px){
    .Portafolio__ppcard h3{
        font-size: 24px;
    }
}

.Portafolio__ppcard h4{
    text-align: justify;
    margin-bottom: 1rem;
    color: #ffffff;
    font-size: 18px;
}

@media only screen and (max-width : 821px){
    .Portafolio__ppcard h4{
        font-size: 15px;
    }
}

.Portafolio__pcard-abstract h5{
    margin-left: 1rem;
    color: #ffffff;
    font-size: 17px;
}

.Portafolio__ppcard p{
    text-align: justify;
    margin: 0 1rem 2rem;
    color: #ffffff;
    font-size: 15px;
}

.Protafolio__ppcard-wrap{
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    display: flex;
}

.Portafolio__pcard-btnBox{
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
}

.Portafolio__pcard-btn{
    margin: 0;
}

@media only screen and (max-width : 821px){
    .Portafolio__pcard-btnBox{
        justify-content: center;
        flex-direction: row;
        align-items: center;
        display: flex;
    }
    
    .Portafolio__pcard-btnBox{
        padding: 0;
    }
}