.Header {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
}

.Navbar {
    justify-content: space-between;
    align-items: center;
    padding: 20px 35px;
    display: flex;
    width: 100%;
}

.Logo {
    transition: color 0.3s;
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 30px;
}

.Logo:hover {
    color: #0ec44a;
}

.nav_links {
    transition: right 1s;
    text-align: right;
    right: -250px;
    flex: 1;
}

.nav_links.active {
    right: 0; 
}

.menu-icon,
.nav_links img {
    cursor: pointer;
    fill: #FFFFFF;
    display: none;
    width: 20px;
    height: auto;
}

@media (max-width: 700px) {
    .menu-icon,
    .nav_links img {
        display: block;
    }
}

.nav_links ul {
    list-style: none;
    padding: 0;
}

.nav_links ul li {
    display: inline-block;
    position: relative;
    margin: 0 8px;
}

.nav_links ul li a {
    text-decoration: none;
    color: #FFFFFF;
}

.nav_links ul li::after {
    transition: width 0.5s;
    background: #0ec44a;
    display: block;
    margin: auto;
    height: 2px;
    content: '';
    width: 0;
}

.nav_links ul li:hover::after {
    width: 100%;
}

@media (max-width: 700px) {
    .nav_links {
        background-color: #000;
        position: absolute;
        text-align: left;
        padding: 30px;
        height: 100vh;
        width: 200px;
        top: 0;
    }

    .nav_links ul li {
        margin-top: 1.5rem;
        display: block;
        padding: 8px 0;
    }

    .menu-icon {
        cursor: pointer;
        display: block;
    }
}

.Navbar_Color_Scroll {
    background-color: rgba(0, 0, 0, 0.7);
    color: #FFFFFF;
}

/* Icon styles */
.menu-icon,
.nav_links img {
    cursor: pointer;
    display: none;
}

@media (max-width: 700px) {
    .menu-icon,
    .nav_links img {
        display: block;
    }
}
