.Portafolio__Projects {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
    margin: 0 auto;
    display: flex;
    width: 900px;
}

@media only screen and (max-width : 700px) {
    .Portafolio__Projects {
        width: 100%;
    }
}

.Portafolio__Projects-title {
    font-size: 4rem;
}

.Portafolio__Projects-cards {
    justify-content: center;
    flex-wrap: wrap;
    margin: 2rem 0;
    display: flex;
    gap: 2rem;
}