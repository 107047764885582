.Portafolio__Social-btn {
    transition: transform 0.5s;
    background: transparent;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: #FFFFFF;
    margin: 0 30px;
    height: 80px;
    width: 80px;
    display: flex;
}

.Portafolio__Social-btn img {
    transition: transform 0.5s, filter 0.5s; 
    filter: invert(100%);
    z-index: 10000;
    height: 40%;
    width: 40%;
}

.Portafolio__Social-btn::after {
    background: linear-gradient(-45deg, #145C9E, #0ec44a);
    position: absolute;
    transition: 0.5s;
    height: 100%;
    width: 100%;
    content: '';
    top: -90px;
    left: 0;
}

.Portafolio__Social-btn:hover::after {
    top: 0;
}

.Portafolio__Social-btn:hover img {
    filter: invert(0%);
}

.Portafolio__Social-btn:hover {
    transform: translateY(-10px);
    background: none;
}

@media (max-width: 821px) {
    .Portafolio__Social-btn {
        margin: 0 15px;
        height: 60px;
        width: 60px;
    }
}
