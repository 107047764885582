.Portafolio__Skills {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
    margin: 0 auto;
    display: flex;
    width: 900px;
}

.Portafolio__Skills-cards {
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem 5rem;
    margin: 2rem 0;
    display: flex;
    width: 100%;
}

@media (max-width: 821px) {
    .Portafolio__Skills {
        width: 100%;
    }
    
    .Portafolio__Skills-cards {
        flex-direction: column;
        align-items: center;
        margin: 1rem auto;
        flex-wrap: wrap;
        padding: 1rem 0;
        width: 90%;
        gap: 2rem;
    }

    .Portafolio__Skills-title {
        font-size: 3rem;
    }
}
