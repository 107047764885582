.Portafolio__Awards{
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
    margin: 0 auto;
    display: flex;
    width: 800px;
}


@media only screen and (max-width: 821px) {
    .Portafolio__Awards {
        width: 80%;
    }
}

.Portafolio__Awards-title{
    font-size: 4rem;
}

.Portafolio__Awards-cards{
    padding: 2rem 0;
}

@media only screen and (max-width : 821px) {
    .Portafolio__Awards-cards {
        gap: 2rem;
        padding: 0rem;
        margin: 2rem 0 0 0rem;
    }
}