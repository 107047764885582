.card{
    box-shadow: 0px 20px 30px 3px rgba(0, 0, 0, 0.55);
    background: transparent;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    display: flex;
    width: 800px;
}

@media only screen and (max-width : 821px){
    .card{
        flex-direction: column;
        padding: 1.5rem;
        width: 90%;
    }
}

.card_left{
    justify-content: center;
    background: transparent;
    align-items: center;
    overflow: hidden;
    display: flex;
    padding: 1rem;
    height: 300px;
    float: left;
    width: 40%;
}

@media only screen and (max-width : 821px){
    .card_left{
        flex-direction: column;
        height: auto;
        width: 100%;
    }
}

.card_left img{
    border-radius: 10px;
    width: 100%;
    transition: transform 0.3s ease;
}

.card:hover .card_left img {
    transform: scale(1.1);
}


.card_right{
    background-color: rgba(255,255,255,0.15);
    border-radius: 0 10px 10px 0;
    height: 300px;
    float: left;
    width: 60%;
}

@media only screen and (max-width : 821px){
    .card_right{
        border-radius: 0 0 10px 10px;
        flex-direction: column;
        height: auto;
        width: 100%;
    }
}

.card_right h3{
    padding: 0 0 0 40px;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: left;
    margin: 30px 0 0;
    font-size: 30px;
    color: white;
}

@media only screen and (max-width : 821px){
    .card_right h3{
        font-size: 24px;
    }
}

.card_right__details ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0 0 0 40px;
    margin: 10px 0 0;
}

.card_right__details ul li{
    padding: 0 40px 0 0;
    color: #e3e3e3;
    font-weight: 400;
    display: inline;
    font-size: 14px;
}

@media only screen and (max-width : 821px){
    .card_right__details ul li{
        font-size: 12px;
    }
}


.card_right__review p{
    text-align: justify;
    letter-spacing: 1px;
    line-height: 20px;
    font-size: 12px;
    padding: 0 40px;
    margin: 1rem 0;
    color: white;
}

.card_right__review a{
    text-decoration: none;
    padding: 0 0 0 40px;
    color: #ffda00;
    font-size: 14px;
    margin: 0;
}

.btn_cont{
    justify-content: center;
    align-items: center;
    display: flex;
}