.Protafolio__button{
    background-color: #000000;
    border-radius: 10px;
    transition: 0.5s;
    font-size: 18px;
    color: #FFFFFF;
    padding: 1rem;
    margin: 1rem;
}

.Protafolio__button:hover{
    transition: 0.5s;
    color: #0ec44a;
}