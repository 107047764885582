.singleSkill {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 5.5rem;
    width: 5.5rem;
    display: flex;
}

.singleSkill h2 {
    transition: color 0.3s;
    color: #ffffff;
    font-size: 17px;
}

.singleSkill img {
    margin-bottom: 0.5rem;
    height: 40px;
    width: 40px;
}

.singleSkill:hover h2 {
    color: #0ec44a;
}

@media (max-width: 821px) {
    .singleSkill h2 {
        transition: color 0.3s;
        color: #ffffff;
        font-size: 15px;
    }

    .singleSkill img{
        height: 30px;
        width: 30px;
    }
}