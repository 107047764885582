.Portafolio__Conferences{
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
    margin: 0 auto;
    display: flex;
    width: 800px;
}

@media only screen and (max-width : 700px){
    .Portafolio__Conferences{
        width: 80%;
    }
}

.Portafolio__Conferences-title{
    text-align: center;
    font-size: 4rem;
}

.Portafolio__Conferences-cards{
    justify-content: center;
    flex-wrap: wrap;
    margin: 2rem 0;
    display: flex;
    gap: 5rem;
}